import React from 'react'

const Features = () => {
    return (
        <section className="wpo-awesome-features-section-s2 section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-4">
                        <div className="wpo-section-title">
                            <h2>Awesome Features</h2>
                            <p>Each element of this theme can be customized according to your requirements with only a few edits.</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-awesome-features-wrapper">
                    <div className="row">
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-sketch"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Mordern Design</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-b"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Bootstrap 5</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-web-programming"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Clean Code</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-user-experience"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Solid UX</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-eye-scan"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Retina Ready</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-exchange"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Fast loading</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-font"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Google Fonts</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-web-browser"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Cross Browser</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-sass"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Use SASS</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-refresh"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Lifetime Update</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-contract"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>Well Documented</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="wpo-awesome-features-item">
                                <div className="wpo-awesome-features-icon">
                                    <i className="fi flaticon-add-file-button"></i>
                                </div>
                                <div className="wpo-awesome-features-text">
                                    <h2>And More</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Features;