import React from 'react'

import em from '../../images/icon/elementor.png'

const Choose = () =>{
    return(
        <section id="demo" className="wpo-features-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-4">
                        <div className="wpo-section-title">
                            <h2>Why Choose Our Theme</h2>
                            <p>Make perfect website with our template. It is fully responsive & retina ready.</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-features-wrapper">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-support"></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Best Customer Support</h2>
                                    <p>Need help? We’re here for you! Questions on your mind? Do not worry we provide in depth answer.</p>
                                </div>
                                <div className="visible-icon">
                                    <i className="fi flaticon-support"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i><img src={em} alt=""/></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Elementor Page Builder</h2>
                                    <p>Elementor is a drag-and-drop page builder for WordPress. This plugin helps you create beautiful pages using a visual editor. </p>
                                </div>
                                <div className="visible-icon">
                                    <i><img src={em} alt=""/></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-settings"></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>Powerful Theme Option</h2>
                                    <p>A Simple and Lightweight WordPress Option Framework. Built  with custom fields and tons of Features. </p>
                                </div>
                                <div className="visible-icon">
                                    <i className="fi flaticon-settings"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Choose;