import React from 'react';

import elogo from '../../images/envato-logo.png'

const Footer = () => {
    return(
        <div>
            <footer className="wpo-site-footer">
                <div className="wpo-upper-footer">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="wpo-footer-text">
                                    <h2>Let’s Build Your Website 
                                        With Consultar. </h2>
                                        <a className="theme-btn-s2" target="_blank" href="https://1.envato.market/consultar-wp
                                        ">Purchase Now </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wpo-lower-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col col-xs-12">
                                <p className="copyright"> Copyright &copy; 2021 Consultar by <a href="https://themeforest.net/user/wpoceans">wpOceans</a>. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a className="wpo-purchase-theme-btn" href="https://1.envato.market/consultar-wp" target="_blank"> 
                <img className="envato-logo" src={elogo} alt=""/> 
                <h2><span>$</span>25</h2>
            </a>
        </div>
    )
}

export default Footer;